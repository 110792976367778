import React, { useState, useEffect } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle sidebar
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  // Function to close sidebar
  const closeNav = () => {
    setIsOpen(false);
  };
  const advertisements = [
    {
      id: 1,
      adNumber: "AD-01/2024-25",
      applyLink: "https://career.facaindia.com",
      lastDate: "25-02-2025",
    },
  ];
  const settings = {
    dots: false, // Navigation dots hide
    arrows: false, // Previous/Next buttons hide
    infinite: true, // Infinite loop
    speed: 1000, // Transition speed (1s)
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Change slide every 3s
    adaptiveHeight: true,
  };
  useEffect(() => {
    const modal = new window.bootstrap.Modal(document.getElementById("exampleModal"));
    modal.show();
  }, []);
  return (
    <div>
      <header>
        <div className="container">
          <div className="header-inner">
            <div className="row align-items-center">
              <div className="col-lg-2 col-6">
                <div className="logo">
                  <a href="javascript:void(0)">
                    <img src="img/logo.svg" alt="logo" />
                  </a>
                </div>
              </div>
              <div className="col-lg-10 col-6">
                <div className="header-menus">
                  <ul className="d-flex list-unstyled justify-content-end align-items-center">
                    <li>
                      <a href="#hero-section" className="active">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#about-us-section">About us</a>
                    </li>
                    <li>
                      <a href="#service-section">Service</a>
                    </li>

                    <li>
                      <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModalJob">
                        Recruitment Advertisements
                      </a>
                    </li>
                    <li>
                      <a href="#contact-section">Contact</a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.faca" className="site-btn btn-orange ms-5">
                        Download Now
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mobile-sidenav-btn">
                  <button className="mobile-toggle-btn" onClick={toggleNav}>
                    <i className="fa fa-bars" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-sidenav-content" id="mySidenav" style={{ width: isOpen ? "250px" : "0" }}>
          <div className="mobile-sidenav-btn sidenav-close-btn">
            <button className="mobile-toggle-btn active" onClick={closeNav}>
              <i className="fa fa-close" />
            </button>
          </div>
          <ul className="list-unstyled">
            <li>
              <a href="#hero-section" className="active" onClick={closeNav}>
                Home
              </a>
            </li>
            <li>
              <a href="#about-us-section" onClick={closeNav}>About us</a>
            </li>
            <li>
              <a href="#service-section" onClick={closeNav}>Service</a>
            </li>

            <li>
              <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModalJob" onClick={closeNav}>
                Recruitment Advertisements
              </a>
            </li>
            <li>
              <a href="#contact-section" onClick={closeNav}>Contact</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.faca" className="site-btn btn-orange ms-5" onClick={closeNav}>
                Download Now
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main>
        {/* ======== Hero section start ===== */}
        <section id="hero-section" className="hero-section">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="slide-web-item">
                <img src="img/banner-img.png" alt="Slide 1" />
                <div className="slider-content">
                  <h1 className="mb-3 h1">Welcome to Federation Of Automobile Customer Association</h1>
                  <p>The aim of the Federation of Automobile Customer Association (FACA) is to protect the rights of every automobile customer...</p>
                </div>
              </div>
              <div className="slide-web-item">
                <img src="img/banner-img.png" alt="Slide 2" />
                <div className="slider-content">
                  <h1 className="mb-3 h1">Welcome to Federation Of Automobile Customer Association</h1>
                  <p>The aim of the Federation of Automobile Customer Association (FACA) is to protect the rights of every automobile customer...</p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/* ======== Hero section End ===== */}
        {/* ======== about us start ===== */}
        <section id="about-us-section" className="about-us-section py-70">
          <div className="container">
            <div className="about-us-inner">
              <div className="row align-items-center">
                <div className="col-md-6 ps-0">
                  <div className="about-us-img">
                    <img src="img/logo.svg" alt="about-us" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-us-content similar-content-section">
                    <h4>About Us</h4>
                    <p>
                      The aim of the Federation of Automobile Customer Association is to protect the rights of every automobile customer. They do this by addressing concerns with automobile dealers, distributors, and manufacturers. Their goal is to ensure that customers are treated fairly and receive the quality of service and products they expect when purchasing automobiles. This includes advocating for transparent business practices, fair pricing, reliable product information, and efficient customer service. By engaging with dealers, distributors, and manufacturers, the association aims to foster a better environment for automobile customers, where their concerns are heard and addressed promptly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== about us end ===== */}
        {/* ======== app-banner start ===== */}
        <section id="service-section" className="app-banner-section">
          <div className="container">
            <div className="app-banner-section-inner">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <div className="app-banner-content-left">
                    <h2 className="mb-3">You can Have your Own App</h2>
                    <p className="mb-4">
                      Download the app and get the best discount
                    </p>
                    <a className="site-btn light-green" href="https://play.google.com/store/apps/details?id=com.faca">
                      Download Now
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="app-banner-content-right text-center">
                    <div className="app-banner-inner">
                      <img src="img/moblie-app.svg" alt="mobile-app" />
                    </div>
                    <div className="app-btn ">
                      <a href="#">
                        <img src="img/google-play.svg" alt="mobile-app" />
                      </a>
                      <a href="#">
                        <img src="img/app-store.svg" alt="mobile-app" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== app-banner end ===== */}
        <div className="scroll_top">
          <a href="javascript:void(0)" id="scroll-button" style={{}}>
            <i className="fa fa-angle-up" />
          </a>
        </div>
      </main>
      <footer id="contact-section" className='ms-0 new-footer'>
        <div className="container">
          <div className="footer-inner">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="footer-number">
                  <ul>
                    <li>
                      <a href="#"><i className="fa fa-map-marker me-2"></i>Corporate office : <br />Shri Rampura Colony, Civil Lines 302006 Jaipur (Rajasthan)</a>
                    </li>
                    <li className='mb-2'>
                      <a href="#"><i className="fa fa-phone me-2"></i>+91 9257019378</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 footer-contant-order">
                <div className="footer-logo">
                  <a href="#">
                    <img src="img/logo.svg" alt="footer-logo" />
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-follow-icon">
                  <ul className="list-unstyled d-flex">
                    <li>
                      <a href="https://www.facebook.com/faca.india">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/faca_india/">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@faca_india">
                        <i className="fa fa-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/faca_india">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 footer-border">
                <ul className="list-unstyled footer-list d-flex justify-content-center align-items-center">
                  <li>
                    <a href="#hero-section">Home</a>
                  </li>
                  <li>
                    <a href="#about-us-section">About</a>
                  </li>
                  <li>
                    <a href="#service-section">Service</a>
                  </li>
                  <li>
                    <a href="#contact-section">Contact</a>
                  </li>
                  <li>
                    <a style={{ color: "#fff" }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModalJob">
                      Recruitment Advertisements
                    </a>
                  </li>
                  <li>
                    <a href="/privacy" target='_blank'>Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/term" target='_blank'>Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/refund" target='_blank'>Refund Policy</a>
                  </li>

                </ul>
              </div>
              <div className="footer-bottom">
                <p>© 2024 FACA. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Recruitment Advertisements</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {/* <div style={{ overflowX: "auto" }}>
                <table className="table-auto w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-4 py-2">S.No</th>
                      <th className="border border-gray-300 px-4 py-2">Advertisement Number</th>
                      <th className="border border-gray-300 px-4 py-2">Detail Advertisement</th>
                      <th className="border border-gray-300 px-4 py-2">Apply Link</th>
                      <th className="border border-gray-300 px-4 py-2">Last Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {advertisements.map((ad, index) => (
                      <tr key={ad.id}>
                        <td className="border border-gray-300 px-4 py-2 text-center">{index + 1}</td>
                        <td className="border border-gray-300 px-4 py-2">{ad.adNumber}</td>
                        <td className="border border-gray-300 px-4 py-2"> <a
                          href="https://career.facaindia.com/images/advt30125.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          Download Detail Advertisement
                        </a></td>
                        <td className="border border-gray-300 px-4 py-2">
                          <a href={ad.applyLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                            https://career.facaindia.com
                          </a>
                        </td>
                        <td className="border border-gray-300 px-4 py-2">{ad.lastDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
              <a href="https://career.facaindia.com/images/advt30125.pdf" target='_blank'>
                <img style={{maxWidth:"100%"}} src="img/carrer.jpeg" alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="exampleModalJob" tabindex="-1" aria-labelledby="exampleModalJobLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalJobLabel">Recruitment Advertisements</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div style={{ overflowX: "auto" }}>
                <table className="table-auto w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-4 py-2">S.No</th>
                      <th className="border border-gray-300 px-4 py-2">Advertisement Number</th>
                      <th className="border border-gray-300 px-4 py-2">Detail Advertisement</th>
                      <th className="border border-gray-300 px-4 py-2">Apply Link</th>
                      <th className="border border-gray-300 px-4 py-2">Last Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {advertisements.map((ad, index) => (
                      <tr key={ad.id}>
                        <td className="border border-gray-300 px-4 py-2 text-center">{index + 1}</td>
                        <td className="border border-gray-300 px-4 py-2">{ad.adNumber}</td>
                        <td className="border border-gray-300 px-4 py-2"> <a
                          href="https://career.facaindia.com/images/advt30125.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          Download Detail Advertisement
                        </a></td>
                        <td className="border border-gray-300 px-4 py-2">
                          <a href={ad.applyLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                            https://career.facaindia.com
                          </a>
                        </td>
                        <td className="border border-gray-300 px-4 py-2">{ad.lastDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Home
